import React, { useReducer } from "react"

export default function NetlifyForm() {
  const initialState = {
    navn: "",
    email: "",
    telefon: "",
    besked: "",
    errorMessage: "",
    succesMessage1: "",
    succesMessage2: "",
  }

  function reducer(state, action) {
    switch (action.type) {
      case "field":
        return { ...state, [action.field]: action.value }
      case "error":
        return { ...state, errorMessage: action.errorMessage }
      case "success":
        return {
          ...state,
          succesMessage1: action.succesMessage1,
          succesMessage2: action.succesMessage2,
        }

      default:
        throw new Error()
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    navn,
    email,
    telefon,
    besked,
    errorMessage,
    succesMessage1,
    succesMessage2,
  } = state

  function updateInput(field, value) {
    dispatch({
      type: "field",
      field: field,
      value: value,
    })
  }

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  function handleSubmit(e) {
    e.preventDefault()

    if (navn.length < 5) {
      dispatch({
        type: "error",
        errorMessage: "Indtast venligst et navn på mindst 5 bogstaver",
      })
      return
    }
    if (email.length < 5) {
      dispatch({
        type: "error",
        errorMessage: "Indtast venligst et en email på mindst 5 tegn",
      })
      return
    }
    if (besked.length < 20) {
      dispatch({
        type: "error",
        errorMessage: "Indtast venligst et en besked på mindst 20 tegn",
      })
      return
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "netlifyForm", ...state }),
    })
      .then(() => {
        dispatch({
          type: "success",
          succesMessage1: "Tak for din henvendelse.",
          succesMessage2:
            "Jeg vil forsøge at vende tilbage, så hurtigt som muligt",
        })
      })
      .catch(error => alert(error))
  }
  {
    return (
      <>
        {!succesMessage1 && !succesMessage2 ? (
          <form
            className="form"
            name="netlifyForm"
            // method="POST"
            // data-netlify="true"
            onSubmit={e => handleSubmit(e)}
            id="netlifyForm"
          >
            <div className="upperWrapper">
              <p>
                <input
                  type="text"
                  name="navn"
                  placeholder="Fulde Navn*"
                  value={navn}
                  onChange={e => {
                    updateInput("navn", e.currentTarget.value)
                  }}
                />
              </p>
              <p>
                <input
                  type="telefon"
                  name="telefon"
                  placeholder="Telefon nummer*"
                  value={telefon}
                  onChange={e => {
                    updateInput("telefon", e.currentTarget.value)
                  }}
                />
              </p>
            </div>
            <p>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={e => {
                  updateInput("email", e.currentTarget.value)
                }}
              />
            </p>
            <p></p>
            <p>
              <textarea
                name="besked"
                placeholder="Besked...  *"
                value={besked}
                onChange={e => {
                  updateInput("besked", e.currentTarget.value)
                }}
              ></textarea>
            </p>
            <div style={{ display: "flex" }}>
              {errorMessage ? (
                <p style={{ fontWeight: "bold" }}> {errorMessage} </p>
              ) : (
                <p style={{ opacity: 0.5 }}> *Påkrævet </p>
              )}
              <button type="submit">Send</button>
            </div>
          </form>
        ) : (
          <div>
            <h1>{succesMessage1}</h1>
            <h2>{succesMessage2}</h2>
          </div>
        )}
      </>
    )
  }
}
