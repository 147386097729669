import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { MdPhone, MdMail } from "react-icons/md"
import "../styles/contact.scss"
import { StaticImage } from "gatsby-plugin-image"
import NetlifyForm from "../components/NetlifyForm"

export default function projekter() {
  return (
    <Layout>
      <Seo title="Kontakt" />

      <div className="contact">
        <div className="leftWrapper">
          <h1>Klar til at bygge...?</h1>
          <h2>Så lad os aftale et møde!</h2>
          <NetlifyForm />
        </div>
        <div className="rightWrapper">
          <StaticImage
            className="contactImage"
            src={"../images/about/about5.jpeg"}
            height={"auto"}
            width={250}
            quality={100}
            alt="Steen Lauth | Tømrer og snedker "
          />
        </div>
      </div>
      <div className="outerWrapper">
        <div>
          <a href="tel:+4541586555">
            <MdPhone />
            <p>(+45) 41 58 65 55</p>
          </a>
        </div>

        <div>
          <a href="mailto:steenlauth@gmail.com">
            <MdMail />
            <p>steenlauth@gmail.com</p>
          </a>
        </div>
        <div>
          <a
            href="https://www.proff.dk/firma/steen-lauth-aps/herlev/tekniske-konsulenter/GWPYBKI01OU/"
            target="_blank"
            rel="noreferrer"
          >
            <p className="CVR">CVR: </p>
            <p>38617923</p>
          </a>
        </div>
      </div>
    </Layout>
  )
}
